import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <Spin />
    </LoadingContainer>
  );
};

export default Loading;
