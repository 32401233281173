import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb, Button } from 'antd';
import { last, get } from 'lodash';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReconciliationOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { NavLink, Link } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import { useAdminContext } from '../../../contexts/AdminContext';

import { ADMIN_PATH } from '../../../Routes';
import './LayoutAdmin.scss';

const { Header, Sider, Content } = Layout;

const ROUTES_WITH_NAME = ['/', '/admin', '/admin/booths', '/admin/configuration', '/admin/agenda'];

const LayoutAdmin = ({ children }: { children?: React.ReactNode }) => {
  const { isChecking, logout } = useAdminContext();
  const [collapsed, setCollapsed] = useState(false);
  const breadcrumbs = useBreadcrumbs();
  const currentPage = last(breadcrumbs);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  if (isChecking) {
    return <Loading />;
  }

  return (
    <Layout className="LayoutAdmin">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">Admin</div>

        <div className="LayoutAdmin__sidebar">
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1" icon={<ReconciliationOutlined />}>
              <NavLink to={`${ADMIN_PATH}/booths`}>Booths</NavLink>
            </Menu.Item>
            <Menu.Item key="2" icon={<SettingOutlined />}>
              <NavLink to={`${ADMIN_PATH}/configuration`}>Configuration</NavLink>
            </Menu.Item>
          </Menu>
          <div className="LayoutAdmin__sidebar__footer">
            <Link to="/" className="ant-btn ant-btn-link ant-btn-block">Visit Site</Link>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          <div className="header-box">
            <div className="header-box__left">
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
              <Breadcrumb className="ml-2 mr-2">
                {React.Children.toArray(
                  breadcrumbs.map(({ breadcrumb, match, key }) => {
                    if (ROUTES_WITH_NAME.includes(key)) {
                      return (
                        <Breadcrumb.Item>
                          <NavLink to={match.url}>{breadcrumb}</NavLink>
                        </Breadcrumb.Item>
                      )
                    }
                    return null;
                  })
                )}
              </Breadcrumb>
            </div>
            <div>
              <Button type="link" onClick={logout}>Logout</Button>
            </div>
          </div>
        </Header>
        <div className="LayoutAdmin__current-page">
          {ROUTES_WITH_NAME.includes(get(currentPage, 'key', '')) && (
            <h1 className="pt-1">{currentPage?.breadcrumb}</h1>
          )}
        </div>
        <Content>
          <div className="site-layout-background">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;
