import React, { useState } from 'react';
import { useLocalize } from 'localize-react';
import { Tooltip } from 'antd';
import { Booth } from '../../utils/interfaces';
import { onOpenMessenger } from '../../utils/displays';
import Youtube from '../../components/Youtube/Youtube';
import './Actions.scss';

interface ActionsProps {
  target?: Booth;
  onGalleryClick: () => void;
}

const Actions = ({ target, onGalleryClick }: ActionsProps) => {
  const [youtube, setYoutube] = useState(false);
  const { translate } = useLocalize();

  if (!target) {
    return null;
  }

  return (
    <div className="actions-menu">
      <Tooltip placement="top" title={translate('chat_on_facebook')}>
        <span className="ant-btn ant-btn-link" onClick={onOpenMessenger(target.facebook)}>
          <i className="fa fa-comments fa-fw" />
        </span>
      </Tooltip>
      <Tooltip placement="top" title={translate('play_video')}>
        <span className="ant-btn ant-btn-link" onClick={() => setYoutube(true)}>
          <i className="fa fa-play fa-fw" />
        </span>
      </Tooltip>
      <Tooltip placement="top" title={translate('drop_an_email')}>
        <a className="ant-btn ant-btn-link" href={`mailto:${target.email}`}>
          <i className="fa fa-envelope fa-fw" />
        </a>
      </Tooltip>
      <Tooltip placement="top" title={translate('gallery')}>
        <span className="ant-btn ant-btn-link" onClick={onGalleryClick}>
          <i className="far fa-images" />
        </span>
      </Tooltip>
      {youtube && <Youtube id={target.live_support} onClose={() => setYoutube(false)} />}
    </div>
  );
};

export default Actions;
