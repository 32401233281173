import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api/author';

export interface AdminContextValue {
  token: string | undefined;
  isChecking: Boolean | undefined;
  logout: () => void;
}

const AdminContext = createContext<AdminContextValue>({
  token: undefined,
  isChecking: true,
  logout: () => {},
});

export const useAdminContext = () => useContext(AdminContext);

export interface AdminProviderProps {}

export const AdminProvider = ({ children }: PropsWithChildren<AdminProviderProps>) => {
  const [token, setToken] = useState<string>('');
  const [isChecking, setIsChecking] = useState<Boolean | undefined>(true);
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const init = async () => {
        try {
          setIsChecking(true);
          const resultToken = await getAccessTokenSilently();
          await api.author(resultToken)
          setToken(resultToken);
          setIsChecking(false);
        } catch (error) {
          history.push('/');
          setIsChecking(false);
          throw error;
        }
      };

      init();
    }
  }, [isAuthenticated, getAccessTokenSilently, setToken, token, history, setIsChecking])

  return (
    <AdminContext.Provider value={{ token, logout, isChecking }}>{children}</AdminContext.Provider>
  );
};
