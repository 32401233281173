import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
} from 'styled-system';
import { responsive, ResponsiveProps, transform, TransformProps } from '../../utils/styles';

export type FlexProps = FlexboxProps &
  PositionProps &
  LayoutProps &
  SpaceProps &
  ColorProps &
  BorderProps &
  BackgroundProps &
  DisplayProps &
  TransformProps &
  ResponsiveProps &
  ShadowProps;

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${display};
  ${color};
  ${background}
  ${border};
  ${flexbox};
  ${layout};
  ${position};
  ${space};
  ${transform};
  ${responsive};
  ${shadow};
`;

export type BoxProps = DisplayProps &
  FlexboxProps &
  PositionProps &
  TransformProps &
  ResponsiveProps;

export const Box = styled.div<BoxProps>`
  ${display};
  ${flexbox};
  ${position};
  ${transform};
  ${responsive};
`;
