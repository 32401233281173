import styled from 'styled-components';
import { color, ColorProps, typography, TypographyProps, space, SpaceProps } from 'styled-system';

export type TextProps = ColorProps & TypographyProps & SpaceProps;

const Text = styled.span<TextProps>`
  ${color}
  ${typography};
  ${space};
`;

export default Text;
