import React from 'react';
import { ThemeProvider } from 'styled-components';
import { LocalizationProvider } from 'localize-react';

import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Routes from './Routes';
import LOCALES from './locales';
import { ConfigurationProvider } from './contexts/ConfigurationContext';
import './App.less';
import './styles/colors.scss';
import './styles/helpers.scss';

const App = () => {
  return (
    <ConfigurationProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider locale="enUS" translations={LOCALES}>
          <GlobalStyle />
          <Routes />
        </LocalizationProvider>
      </ThemeProvider>
    </ConfigurationProvider>
  );
};

export default App;
