import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer } from 'react';
import { Config } from '../utils/interfaces';
import Loading from '../components/Loading/Loading';
import api from '../api';
import { dataLoadingReducer, initData, IDataState, Action, ActionType } from '../reducer/data-loading-reducer';
import { STATUS } from '../reducer/data-loading-reducer';

export interface ConfigurationContextValue {
  config?: Config;
  status: ActionType,
  dispatch: (value: any) => void
}

const ConfigurationContext = createContext<ConfigurationContextValue>({ config: {} as Config, status: '', dispatch: (value) => {} });

export const useConfigurationContext = () => useContext(ConfigurationContext);

export interface ConfigurationProviderProps {}

export const ConfigurationProvider = ({
  children,
}: PropsWithChildren<ConfigurationProviderProps>) => {
  const [{ status, data }, dispatch] = useReducer<React.Reducer<IDataState<Config>, Action>>(
    dataLoadingReducer,
    initData
  );

  useEffect(() => {
    const init = async () => {
      try {
        dispatch({ type: 'DATA_LOADING' });
        const result = await api.workshop.findOne();
        dispatch({ type: 'DATA_LOADED', payload: result });
      } catch (error) {
        dispatch({ type: 'DATA_ERROR' });
        throw error;
      }
    };

    init();
  }, []);

  if (status === STATUS.LOADING) {
    return <Loading />;
  }

  const config = data as Config;

  return (
    <ConfigurationContext.Provider value={{ config, status, dispatch }}>{children}</ConfigurationContext.Provider>
  );
};
