import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DESKTOP_SIZE } from '../../enums';

interface Props {
  children: React.ReactElement;
}

const Desktop: React.FC<Props> = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: DESKTOP_SIZE });
  return isDesktop ? children : null;
};

const Mobile: React.FC<Props> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: DESKTOP_SIZE - 1 });
  return isMobile ? children : null;
};

export { Desktop, Mobile };
