import { IAgenda, Booth, BoothGallery, Config } from '../utils/interfaces';

export const STATUS = {
  ERROR: 'DATA_ERROR',
  LOADING: 'DATA_LOADING',
  LOADED: 'DATA_LOADED',
  UPDATING: 'DATA_UPDATING',
  UPDATE_FAIL: 'UPDATE_FAIL'
};

export type ActionType = undefined | string;

type PayloadType = IAgenda | Booth | Booth[] | BoothGallery | BoothGallery[] | Config;

export interface Action {
  type: ActionType;
  payload?: PayloadType;
}

export interface IDataState<T> {
  status: ActionType;
  data: undefined | T;
}

export const initData = {
  status: undefined,
  data: undefined,
};

export function dataLoadingReducer(state: IDataState<any>, action: Action): IDataState<any> {
  switch (action.type) {
    case STATUS.LOADING:
      return {
        ...state,
        status: STATUS.LOADING,
      };
    case STATUS.LOADED:
      return {
        ...state,
        status: STATUS.LOADED,
        data: action.payload,
      };
    case STATUS.ERROR: {
      return {
        ...state,
        status: STATUS.ERROR
      }
    }
    case STATUS.UPDATE_FAIL: {
      return {
        ...state,
        status: STATUS.UPDATE_FAIL
      }
    }
    default:
      return state;
  }
}