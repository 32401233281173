import React, { useEffect, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Input, Upload, Row, Col, Skeleton, message, Switch } from 'antd';
import { useLocalize } from 'localize-react';
import styled from 'styled-components';

import PreviewImage from '../../../components/PreviewImage/PreviewImage';
import api from '../../../api';
import { Booth } from '../../../utils/interfaces';
import { Flex } from '../../../components/Flex/Flex';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ADMIN_PATH } from '../../../Routes';
import { STATUS, Action, dataLoadingReducer, initData, IDataState } from '../../../reducer/data-loading-reducer';
import './Booth.scss';

const UploadContainer = styled.div`
  padding-top: 29px;
`;

const PreviewFeatureImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  min-height: 400px;
`;

const BoothComponent = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const { translate } = useLocalize();
  // @ts-ignore
  const { id } = useParams();
  const { accessToken: token } = useAuthContext();
  const [{ status, data }, dispatch] = useReducer<React.Reducer<IDataState<Booth>, Action>>(
    dataLoadingReducer,
    initData
  );

  const [form] = Form.useForm();
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingFeatureImage, setLoadingFeatureImage] = useState(false);
  const [loadingGallery, setLoadingGallery] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        dispatch({ type: 'DATA_LOADING' });
        const newData = await api.booths.findOne(id);
        dispatch({ type: 'DATA_LOADED', payload: newData });
      } catch (error) {
        dispatch({ type: 'DATA_ERROR' });
        throw error;
      }
    };

    if (id === 'new') {
      return;
    }

    init();
  }, [id]);

  const onSubmit = async (form: object) => {
    setIsLoading(true)
    try {
      dispatch({ type: 'DATA_UPDATING' });
      const { galleries = [], ...rest } = form as any;
      const gallery = galleries.split('\n').map((url: string) => ({ type: 'image', url }));
      const newData = await api.booths.createOrUpdate(
        { ...data, ...rest, gallery, hidden: isHidden.toString() } as Booth,
        token || ''
      );
      dispatch({ type: 'DATA_LOADED', payload: newData });
      message.success(translate('added_success'));
      if (id === 'new') {
        history.replace(`${ADMIN_PATH}/booths/${newData.id}`);
      }
      setIsLoading(false)
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL' });
      setIsLoading(false)
      throw error;
    }
  };

  const onUploadLogo = (file: File) => {
    const upload = async () => {
      const url = await api.files.upload(file, token || '');

      form.setFieldsValue({ logo: url });
      setLoadingLogo(false);
    };
    setLoadingLogo(true);
    upload();

    return false;
  };

  const onUploadFeatureImage = (file: File) => {
    const upload = async () => {
      const url = await api.files.upload(file, token || '');

      form.setFieldsValue({ feature_image: url });
      setLoadingFeatureImage(false);
    };
    setLoadingFeatureImage(true);
    upload();

    return false;
  };

  const onUploadGallery = (file: File) => {
    const upload = async () => {
      const url = await api.files.upload(file, token || '');
      const galleries = (form.getFieldValue('galleries') || '').split('\n').filter(Boolean);
      galleries.push(url);
      form.setFieldsValue({ galleries: galleries.join('\n') });
      setLoadingGallery(false);
    };

    setLoadingGallery(true);
    upload();
    return false;
  };

  const onRemove = async () => {
    await api.booths.removeOne(id, token || '');
    history.goBack();
  };

  return (
    <Flex margin="24px" flex="0 0 50%" flexDirection="column">
      {status === STATUS.LOADING && <Skeleton active />}
      {![STATUS.LOADING, STATUS.ERROR].includes(status || '') && (
        <>
          <div className="d-flex justify-content-between">
            <h2>{id === 'new' ? 'New' : 'Update'} booth</h2>
            <div>
              {id !== 'new' &&
                <Button type="primary" danger onClick={onRemove}>
                  Delete this booth
              </Button>}
            </div>
          </div>
          {(data || id === 'new') && (
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                ...data,
                galleries: data?.gallery?.map(({ url }: { url: string }) => url).join('\n'),
              }}
              onFinish={onSubmit}
            >
              <Row gutter={30}>
                <Col span="16">
                  <Row>
                    <Form.Item label="Hide this booth" name="no_exhibition" rules={[]}>
                      <Switch defaultChecked={data?.hidden === 'true'} onChange={setIsHidden} />
                    </Form.Item>
                  </Row>
                  <Form.Item label="Name*" name="name" rules={[]}>
                    <Input required />
                  </Form.Item>
                  <Form.Item label="Description*" name="description" rules={[]}>
                    <Input required />
                  </Form.Item>
                  <Row gutter={16} className="mb-1">
                    <Col span="3">
                      <PreviewImage src={data?.logo} />
                    </Col>
                    <Col span="17">
                      <Form.Item label="Logo*" name="logo" rules={[]}>
                        <Input required disabled={loadingLogo} />
                      </Form.Item>
                    </Col>
                    <Col span="4">
                      <UploadContainer>
                        <Upload
                          beforeUpload={onUploadLogo}
                          showUploadList={false}
                          disabled={loadingLogo}
                        >
                          <Button disabled={loadingLogo} loading={loadingLogo}>
                            Upload
                          </Button>
                        </Upload>
                      </UploadContainer>
                    </Col>
                  </Row>

                  <Form.Item label="Username Facebook" name="facebook" rules={[]}>
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item label="Youtube ID" name="live_support" rules={[]}>
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item label="Email*" name="email" rules={[]}>
                    <Input required />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span="20">
                      <Form.Item label="Gallery*" name="galleries">
                        <Input.TextArea required disabled={loadingGallery} style={{ height: 200 }} />
                      </Form.Item>
                      <div className="gallery-preview-container">
                        {React.Children.toArray(data?.gallery.map(item => (
                          <div className="gallery-preview-item">
                            <PreviewImage src={item?.url} />
                          </div>
                        )))}
                      </div>
                    </Col>
                    <Col span="4">
                      <UploadContainer>
                        <Upload
                          beforeUpload={onUploadGallery}
                          showUploadList={false}
                          disabled={loadingGallery}
                        >
                          <Button disabled={loadingGallery} loading={loadingGallery}>
                            Upload
                          </Button>
                        </Upload>
                      </UploadContainer>
                    </Col>
                  </Row>
                </Col>
                <Col span="8">
                  <div>
                    <PreviewFeatureImage>
                      <PreviewImage src={data?.feature_image} />
                    </PreviewFeatureImage>
                    <Form.Item label="Feature Image" name="feature_image" rules={[]}>
                      <Input />
                    </Form.Item>
                    <Upload
                      beforeUpload={onUploadFeatureImage}
                      showUploadList={false}
                      disabled={loadingFeatureImage}
                    >
                      <Button disabled={loadingFeatureImage} loading={loadingFeatureImage}>
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </Col>
              </Row>

              <Form.Item>
                <Button disabled={isLoading} htmlType="submit" type="primary"  >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </>
      )}
    </Flex>
  );
};

export default BoothComponent;
