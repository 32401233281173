import { css, FlattenSimpleInterpolation } from 'styled-components';

export type MediaQueries = {
  mobile: string;
  desktop: string;
};

export type MediaQueriesKey = keyof MediaQueries;

export const mediaQueries: MediaQueries = {
  mobile: `@media (min-aspect-ratio: 375/812)`,
  desktop: `@media (min-aspect-ratio: 568/320)`,
};

export type TransformProps = {
  transform?: string;
};

export const transform = css<TransformProps>`
  transform: ${({ transform }) => transform};
`;

export type Media = {
  mobile: (str: FlattenSimpleInterpolation) => FlattenSimpleInterpolation;
  desktop: (str: FlattenSimpleInterpolation) => FlattenSimpleInterpolation;
};

export const media: Media = Object.keys(mediaQueries).reduce(
  (acc, key) => {
    const name = key as MediaQueriesKey;

    return {
      ...acc,
      [key]: (str: FlattenSimpleInterpolation) => css`
        ${mediaQueries[name]} {
          ${str};
        }
      `,
    };
  },
  { mobile: () => css``, desktop: () => css`` }
);

export type ResponsiveProps = {
  mobile?: FlattenSimpleInterpolation;
  desktop?: FlattenSimpleInterpolation;
};

export const responsive = css<ResponsiveProps>`
  ${({ mobile }) => mobile && media.mobile(mobile)};
  ${({ desktop }) => desktop && media.desktop(desktop)};
`;

export const hidden = css`
  display: none;
`;

export const flex = css`
  display: flex;
`;
