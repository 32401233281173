import { IAgenda } from '../utils/interfaces';
import { createRequest } from '../utils/request';

const { REACT_APP_API_ENDPOINT } = process.env;

const { get, put, post } = createRequest({ baseURL: `${REACT_APP_API_ENDPOINT}/agenda` });

const findAll = async (token: string): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const { data } = await get('', { headers });
    if (data) {
      return JSON.parse(data);
    }
    return [];
  } catch (error) {
    throw error;
  }
};

const createOrUpdate = async ({ id, token, data } : {
    id?: string,
    token: string,
    data: IAgenda[]
}): Promise<IAgenda[]> => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
  
    if (!id) {
      return post('', { data: JSON.stringify(data), headers });
    }
  
    return put(`/${id}`, { data: JSON.stringify(data), headers });
  };

export default { findAll, createOrUpdate };
