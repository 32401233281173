import styled from 'styled-components';

interface IBrandBox {
  image?: string;
  crossOrigin?: string;
  color?: string;
}

const BrandBox = styled.div` 
  background-image: url("${(props: IBrandBox) => props.image || ''}");
  background-color: ${(props: IBrandBox) => props.color || 'transparent'};
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @media (orientation: landscape) {
    background-size: contain;
  }
`;

export default BrandBox;
