import React from 'react';
import styled, { css } from 'styled-components';

import Carousel from '../../../components/Carousel/Carousel';
import { Booth } from '../../../utils/interfaces';
import Layout from '../../../components/Layout/Layout';
import { Flex, FlexProps } from '../../../components/Flex/Flex';
import { media } from '../../../utils/styles';

type GalleryItemProps = FlexProps & {
  url: string;
};

const GalleryItem = styled(Flex).attrs(() => ({ flex: '1 1 auto' }))<GalleryItemProps>(
  ({ url }) => css`
    background-image: url('${url}');
    background-size: cover;
    background-repeat: no-repeat;

    ${media.mobile(css`
      border-radius: unset;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    `)};

    ${media.desktop(css`
      border-radius: unset;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    `)};
  `
);

export interface ExhibitionGalleryProps {
  data?: Booth;
}

const ExhibitionGallery = ({ data }: ExhibitionGalleryProps) => {
  return (
    <Layout>
      <Carousel
        items={
          data?.gallery?.map(({ url }) => {
            return <GalleryItem url={url} />;
          }) || []
        }
      />
    </Layout>
  );
};

export default ExhibitionGallery;
