import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import { Icon } from '../Icon/Icon';

const CustomModal = styled(Modal)`
  && {
    .ant-modal-content,
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-body {
      position: relative;
    }
  }
`;

export interface YoutubeModalProps {
  id: string;
  onClose: () => void;
}

const Youtube = ({ id, onClose }: YoutubeModalProps) => {
  return (
    <CustomModal
      visible
      footer={false}
      closable={false}
      width="80vw"
      bodyStyle={{ height: '60vh' }}
    >
      <iframe
        width="100%"
        height="100%"
        title="Youtube"
        frameBorder="0"
        src={`https://www.youtube.com/embed/${id}`}
        allowFullScreen
      />
      <Icon
        className="fas fa-times"
        position="absolute"
        top="0"
        right="-24px"
        fontSize="24px"
        onClick={onClose}
      />
    </CustomModal>
  );
};

export default Youtube;
