import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Input } from 'antd';
import './SearchBox.scss';
import { useDebounce } from '../../hooks';

const SearchBox = ({ onSearch }: { onSearch?: (value: string) => void }) => {
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 300);

  const handleClickSearchButton = () => {
    setShowSearch(!showSearch);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }

  useEffect(() => {
    onSearch && onSearch(debouncedSearchString);
  }, [onSearch, debouncedSearchString])

  return (
    <div className={`SearchBox ${showSearch ? 'show-search' : 'hide-search'}`}>
      <Input.Search
        className="search_input"
        placeholder="Search..."
        allowClear={true}
        onChange={onChange}
        onSearch={(value: any, event: any) => onSearch && onSearch(value)}
      />
      <div className="search_icon">
        <Button shape="circle" size="large" onClick={handleClickSearchButton}>
          <i className="fas fa-search fa-fw" />
        </Button>
      </div>
    </div>
  );
};

export default SearchBox;
