import { createRequest } from '../utils/request';
import { Config } from '../utils/interfaces';

const { REACT_APP_API_ENDPOINT, REACT_APP_WORKSHOP_ID } = process.env;

const { get, put, post } = createRequest({ baseURL: `${REACT_APP_API_ENDPOINT}/workshops` });

const findAll = async (): Promise<Config[]> => {
  const { data } = await get('');

  return data;
};

const findOne = async (): Promise<Config> => {
  return get(`/${REACT_APP_WORKSHOP_ID}`);
};

const createOrUpdate = async (config: Config, token: string): Promise<Config> => {
  const headers = {
    Authorization: `Bearer ${token}`
  };

  if (!config.id) {
    return post('', { data: config, headers });
  }

  return put(`/${config.id}`, { data: config, headers });
};

export default { findAll, findOne, createOrUpdate };
