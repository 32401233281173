import React from 'react';
import Layout from '../../components/Layout/Layout';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';
import './Workshop.scss'


const Workshop = () => {
  const { config } = useConfigurationContext();

  return (
    <Layout>
      <div className="Workshop" style={{ backgroundImage: `url('/images/workshop-bg.svg')`}}>
        <div className="iframe-container">
          <iframe
            title="Workshop"
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/viewer?&time=${new Date().getTime()}&url=${
              config?.document
            }&embedded=true`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Workshop;
