import { createRequest } from '../utils/request';
import { Booth } from '../utils/interfaces';

const { REACT_APP_API_ENDPOINT } = process.env;

const { get, put, post, remove } = createRequest({ baseURL: `${REACT_APP_API_ENDPOINT}/booths` });


export const filterHiddenBooth = (item: any) => {
  return !item.hidden || item.hidden !== 'true'
}

const findAll = async (params?: { name: string; description: string, noFilter?: boolean }): Promise<Booth[]> => {
  const { data } = await get('', { params });
  if (params?.noFilter) {
    return data;
  }

  return data.filter(filterHiddenBooth);
};

const findOne = async (id: string): Promise<Booth> => {
  return get(`/${id}`);
};

const removeOne = async (id: string, token: string): Promise<void> => {
  const headers = {
    // 'x-api-key': token,
    Authorization: `Bearer ${token}`
  };
  return remove(`/${id}`, { headers });
};

const createOrUpdate = async (data: Booth, token: string): Promise<Booth> => {
  const headers = {
    // 'x-api-key': token,
    Authorization: `Bearer ${token}`
  };

  if (!data.id) {
    return post('', { data, headers });
  }

  return put(`/${data?.id}`, { data, headers });
};

export default { findAll, findOne, createOrUpdate, removeOne };
