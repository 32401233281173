import React, { useState } from 'react';
import { useLocalize } from 'localize-react';
import { Link } from 'react-router-dom';

import { Box, Flex } from '../../components/Flex/Flex';
import Loading from '../../components/Loading/Loading';
import { EXHIBITION_PATH, WORKSHOP_PATH, GALLERY_PATH } from '../../Routes';
import Menu from '../../components/Menu/Menu';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';
import './Home.scss';

const Home = () => {
  const [loadedContent, setLoadedContent] = useState(false);
  const { config } = useConfigurationContext();
  const { translate } = useLocalize();

  const onLoad = React.useCallback(() => {
    setLoadedContent(true);
  }, [setLoadedContent]);

  return (
    <Flex flex="1 1 auto" position="relative" className="Home">
      <div className="iframe-holder">
        {!loadedContent && (
          <>
            <div className="iframe-loading-indicator">
              <Loading />
            </div>
            <div className="bg-cover" />
            <div className="bg-iframe" style={{ backgroundImage: `url('${config?.home}')` }} />
          </>
        )}
        <iframe
          title={translate('Home.iframe')}
          onLoad={onLoad}
          width="100%"
          height="100%"
          src={config?.url3d}
        />
      </div>
      <Box position="absolute" zIndex={25} top={['24px', '24px']} left={['24px', '24px']}>
        <Menu />
      </Box>
      <div className="button-group">
        <Link className="ant-btn ant-btn-round ant-btn-lg" to={EXHIBITION_PATH}>
          {translate('common.exhibition')}
        </Link>
        <Link className="ant-btn ant-btn-round ant-btn-lg" to={GALLERY_PATH}>
          {translate('common.gallery')}
        </Link>
        <Link className="ant-btn ant-btn-round ant-btn-lg" to={WORKSHOP_PATH}>
          {translate('common.workshop')}
        </Link>
      </div>
    </Flex>
  );
};

export default Home;
