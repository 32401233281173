import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Skeleton, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';

import { Booth } from '../../../utils/interfaces';
import { ADMIN_PATH } from '../../../Routes';
import api from '../../../api';
import { STATUS } from '../../../reducer/data-loading-reducer';

import './Booths.scss'

const BOOTH_COLUMNS = [
  { id: 'id', title: 'ID', key: 'id', dataIndex: 'id' },
  {
    id: 'name',
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (_key: string, item: Booth) => (
      <Link to={`${ADMIN_PATH}/booths/${item.id}`}>{item.name}</Link>
    ),
  },
  {
    id: 'hidden', title: 'Hidden', key: 'hidden', dataIndex: 'hidden', render: (_key: string, item: Booth) => (
      <Checkbox defaultChecked={item.hidden === 'true'} disabled />
    ),
  },
  { id: 'description', title: 'Description', key: 'description', dataIndex: 'description' },
  { id: 'edit', title: 'Action', key: 'edit', render: (_key: string, item: Booth) => <Link to={`${ADMIN_PATH}/booths/${item.id}`}>Edit</Link> },
];

const Booths = () => {
  const history = useHistory();
  const [status, setStatus] = useState('');
  const [booths, setBooths] = useState<Booth[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        setStatus(STATUS.LOADING);
        const result = await api.booths.findAll({ name: '', description: '', noFilter: true })
        setBooths(result);
        setStatus('');
      } catch (error) {
        setStatus(STATUS.ERROR);
      }
    };

    init();
  }, []);

  return (
    <>
      <div className="Booths">
        <div className="d-flex justify-content-between mb-2">
          <div>
            <Input.Search
              placeholder="Search booths"
              allowClear
              onSearch={async (value: string) => {
                setBooths(await api.booths.findAll({ name: value, description: value, noFilter: true }));
              }}
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Button
              key="1"
              onClick={() => history.push(`${ADMIN_PATH}/booths/new`)}
              icon={<PlusOutlined />}
              type="primary"
            >
              Add Booth
            </Button>
          </div>
        </div>
        {status === STATUS.LOADING && (
          <Skeleton active />
        )}
        {![STATUS.LOADING, STATUS.ERROR].includes(status) &&
          <Table
            rowClassName={(record) => record.hidden === 'true' ? 'booth-disabled' : 'table-row-dark'}
            columns={BOOTH_COLUMNS} dataSource={booths} rowKey="id" />
        }
      </div>
    </>
  );
};

export default Booths;
