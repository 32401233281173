import { createRequest } from '../utils/request';

const { REACT_APP_API_ENDPOINT } = process.env;

const { get } = createRequest({ baseURL: `${REACT_APP_API_ENDPOINT}/upload` });

const upload = async (selectedFile: File, token: string): Promise<string> => {
  const headers = {
    // 'x-api-key': token,
    Authorization: `Bearer ${token}`,
    'x-amz-meta-file-content-type': selectedFile.type,
  };

  try {
    const signedUrl: string = await get('', {
      headers,
    });

    const { put } = createRequest({ baseURL: signedUrl });

    await put('', { data: selectedFile, headers: { 'Content-Type': selectedFile.type } });
    const uploadedUrl = new URL(signedUrl);
    Array.from(uploadedUrl.searchParams.keys()).forEach((key: string) =>
      uploadedUrl.searchParams.delete(key)
    );

    return uploadedUrl.href;
  } catch (e) {
    console.log(e);
  }

  return '';
};

export default { upload };
