import styled, { css } from 'styled-components';
import { position, PositionProps, space, SpaceProps } from 'styled-system';

import { responsive, ResponsiveProps, transform, TransformProps } from '../../utils/styles';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type ButtonProps = PositionProps & SpaceProps & ResponsiveProps & TransformProps;

const Button = styled.button<ButtonProps>(
  () => css`
    ${position};
    ${space};
    ${responsive};
    ${transform};
    cursor: pointer;
  `
);

export type MainButtonProps = ButtonProps;

export const MainButton = styled(Button)<MainButtonProps>(
  () => css`
    background-color: #857d70;
    border: none;
    border-radius: 8px;
    box-shadow: none;
    outline: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    padding: 8px 16px;
  `
);

export type CircleProps = { state?: ButtonType };

export type CircleButtonProps = ButtonProps & CircleProps;

export const CircleButton = styled(Button)<CircleButtonProps>(
  ({ theme, state = ButtonType.Primary }) => {
    const { backgroundColor, boxShadow, color } = theme.button.circle[state];

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${backgroundColor};
      box-shadow: ${boxShadow};
      border: none;
      border-radius: 100%;
      color: ${color};

      &:hover,
      &:focus {
        outline: none;
        filter: brightness(1.2);
      }

      @media (min-width: 0) {
        font-size: 10px;
        width: 48px;
        height: 48px;

        .fa-fw {
          font-size: 24px;
        }
      }

      @media (min-width: ${theme.breakpoints[0]}) {
        font-size: 14px;
        width: 80px;
        height: 80px;

        .fa-fw {
          font-size: 36px;
        }
      }
    `;
  }
);
