import React from 'react';
import { map } from 'lodash';
import { useLocalize } from 'localize-react';
import Slider from 'react-slick';
import { Button } from 'antd';
import ClientLogo from '../../components/ClientLogo/ClientLogo';
import BrandBox from '../../components/BrandBox/BrandBox';
import { onOpenMessenger } from '../../utils/displays';
import { Booth } from '../../utils/interfaces';
import './ExhibitionGallery.scss';

interface ExhibitionGalleryProps {
  target?: Booth;
}

const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Actions = ({ data }: { data?: Booth }) => {
  const { translate } = useLocalize();

  if (!data) {
    return null;
  }

  return (
    <div className="ActionsContainer">
      <Button type="link" size="large" onClick={onOpenMessenger(data?.facebook)}>
        <i className="fas fa-comments fa-fw pr-2" />
        {translate('common.chat')}
      </Button>
      <a className="ant-btn ant-btn-link ant-btn-lg" href={`mailto:${data.email}`}>
        {translate('common.email')}
      </a>
    </div>
  );
};

const ExhibitionGallery = ({ target }: ExhibitionGalleryProps) => {
  return (
    <div className="ExhibitionGallery">
      <div className="ExhibitionGallery__left">
        <div className="ExhibitionGallery__left-inner">
          <Slider {...settings}>
            {React.Children.toArray(
              map(target?.gallery, (item) => (
                <div>
                  <div className="exhibition-gallery-item">
                    <div className="exhibition-gallery-item__container">
                      <div className="exhibition-gallery-item__image">
                        <BrandBox className="brand-box" image={item.url} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </div>
      <div className="ExhibitionGallery__right">
        <div className="ExhibitionGallery__right-inner">
          <div className="ExhibitionGallery__info">
            <div className="ExhibitionGallery__info__body">
              <div className="ExhibitionGallery__group">
                <div className="ExhibitionGallery__logo">
                  <ClientLogo image={target?.logo} />
                </div>
                <div className="ExhibitionGallery__group-body">
                  <h3 className="ExhibitionGallery__company-name">{target?.name}</h3>
                  <p className="ExhibitionGallery__desc">{target?.description}</p>
                </div>
              </div>
              <p className="ExhibitionGallery__long-desc"></p>
            </div>
            <div className="ExhibitionGallery__info__footer">
              <Actions data={target} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionGallery;
