import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useState } from 'react';
import { get, forEach, map } from 'lodash';
import { Booth, BoothGallery } from '../utils/interfaces';
import { dataLoadingReducer, initData, IDataState, Action, ActionType } from '../reducer/data-loading-reducer';
import api from '../api';
import { filterHiddenBooth } from '../api/booths'

export interface BoothContextValue {
  status: ActionType;
  /** gallery data, build base on booth data */
  galleries: BoothGallery[];
  /** booth data */
  data: Booth[];
  /** function to handle search data with API */
  onSearch: (value: string) => void;
  /** callback when display detail */
  changeViewDetail: (value: boolean) => void;
  /** identify is it view detail, this one to lock interactive on popup display */
  isViewDetail: boolean;
}

const BoothContext = createContext<BoothContextValue>({
  status: undefined,
  galleries: [],
  data: [],
  onSearch: () => { },
  changeViewDetail: () => { },
  isViewDetail: false,
});

export const useBoothContext = () => useContext(BoothContext);

export interface BoothContextProps { }

/**
 * Helper to transform data for gallery
 * @param data
 */
const transformGallery = (data: Booth[]): BoothGallery[] => {
  let returnData: BoothGallery[] = [];
  forEach(data.filter(filterHiddenBooth), (current) => {
    const newGallery = map(get(current, 'gallery'), (item: BoothGallery) => ({
      ...item,
      name: current.name,
      description: current.description,
    }));

    returnData = [...returnData, ...newGallery];
  });
  return returnData;
};

export const BoothProvider = ({ children }: PropsWithChildren<BoothContextProps>) => {
  const [{ status, data }, dispatch] = useReducer<React.Reducer<IDataState<Booth[]>, Action>>(
    dataLoadingReducer,
    initData
  )
  const [galleries, setGalleries] = useState<BoothGallery[]>([]);
  const [search, setSearch] = useState('');
  const [isViewDetail, setIsViewDetail] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        dispatch({ type: 'DATA_LOADING' });
        const newData = await api.booths.findAll({ description: search || '', name: search || '' });
        dispatch({ type: 'DATA_LOADED', payload: newData });
        setGalleries(transformGallery(newData));
      } catch (error) {
        dispatch({ type: 'DATA_ERROR' });
        throw error;
      }
    };

    init();
  }, [search]);

  const onSearch = (value: string) => {
    setSearch(value.toLowerCase());
  };

  const changeViewDetail = React.useCallback(
    (value: boolean) => {
      setIsViewDetail(value);
    },
    [setIsViewDetail]
  );
  const preferData = data as Booth[];

  return (
    <BoothContext.Provider value={{ status, data: preferData, onSearch, isViewDetail, changeViewDetail, galleries }}>
      {children}
    </BoothContext.Provider>
  );
};
