import styled from 'styled-components';
import { DESKTOP_SIZE } from '../../enums';

interface IClientLogo {
  image?: string;
  color?: string;
}

const ClientLogo = styled.div`  
  background-color: ${(props: IClientLogo) => props.color || '#fff'};  
  border-radius: 50%; 
  width: 58px;
  height: 58px;     
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  @media (min-width: ${DESKTOP_SIZE}px) {
    width: 100px;
    height: 100px; 
  }
  @media (min-width: 1024px) {
    width: 125px;
    height: 125px;
  }
  @media (orientation: landscape) {
    width: 50px;
    height: 50px; 
  }
  &:before {
    content: "";
    display: block;
    background-image: url("${(props: IClientLogo) => props.image || ''}");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;  
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 70%;
    height: 70%;
  }  
`;

export default ClientLogo;
