import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Layout from '../../components/Layout/Layout';
import { Flex } from '../../components/Flex/Flex';
import { hidden, flex } from '../../utils/styles';
import { Icon } from '../../components/Icon/Icon';
import { CircleButton } from '../../components/Button/Button';
import { Booth } from '../../utils/interfaces';
import api from '../../api';
import ExhibitionGallery from './ExhibitionGallery/ExhibitionGallery';
import ExhibitionInformation from './ExhibitionInformation/ExhibitionInformation';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';

const ExhibitionDetailContainer = styled(Flex).attrs(() => ({ flex: '1 1 auto', minHeight: 0 }))`
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
`;

export interface ExhibitionDetailProps {
  data?: Booth;
}

const ExhibitionDetailDesktop = ({ data }: ExhibitionDetailProps) => {
  const history = useHistory();

  return (
    <ExhibitionDetailContainer
      mobile={hidden}
      desktop={flex}
      margin="20vh 15vw"
      position="relative"
    >
      <CircleButton
        onClick={() => history.goBack()}
        position="absolute"
        top="50%"
        left="-10%"
        transform="translate(-50%, -50%)"
      >
        <Icon className="fa fa-chevron-left fa-fw" />
      </CircleButton>
      <Flex flex="50%">
        <ExhibitionGallery data={data} />
      </Flex>
      <Flex flex="50%">
        <ExhibitionInformation data={data} />
      </Flex>
    </ExhibitionDetailContainer>
  );
};

const ExhibitionDetailMobile = ({ data }: ExhibitionDetailProps) => {
  const history = useHistory();

  return (
    <ExhibitionDetailContainer
      mobile={flex}
      desktop={hidden}
      margin="12vh 24px"
      flexDirection="column"
    >
      <Flex flex="0 0 50%" position="relative">
        <ExhibitionGallery data={data} />
        <Flex position="absolute" top="24px" left="16px" onClick={() => history.goBack()}>
          <Icon className="fas fa-arrow-left" color="#ffffff" fontSize="20px" />
        </Flex>
      </Flex>
      <Flex flex="0 0 50%">
        <ExhibitionInformation data={data} />
      </Flex>
    </ExhibitionDetailContainer>
  );
};

const ExhibitionDetail = () => {
  const { id } = useParams();
  const { config } = useConfigurationContext();
  const [data, setData] = useState<Booth>();

  useEffect(() => {
    const init = async () => {
      setData(await api.booths.findOne(id));
    };

    init();
  }, [id]);

  return (
    <Layout backgroundImage={config?.booth}>
      <ExhibitionDetailDesktop data={data} />
      <ExhibitionDetailMobile data={data} />
    </Layout>
  );
};

export default ExhibitionDetail;
