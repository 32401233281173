import React from 'react'
import PropTypes from 'prop-types'

import BrandBox from '../../components/BrandBox/BrandBox';
import PreviewImage from '../../components/PreviewImage/PreviewImage';
import Actions from './Actions';

const ExhibitionItem = ({ handleShowGallery, item, isShowActions, toggleActions }) => {
  return (
    <div>
      <div className="slide-exhibition-item" onClick={toggleActions}>
        {isShowActions && <Actions target={item} onGalleryClick={() => handleShowGallery(item)} />}
        <div className="slide-exhibition-item__container">
          <div className="slide-exhibition-item__image">
            {item?.feature_image ? (
              <BrandBox image={item?.feature_image} />
            ) : (
                <PreviewImage />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

ExhibitionItem.propTypes = {
  handleShowGallery: PropTypes.func,
  item: PropTypes.any,
  isShowActions: PropTypes.bool,
  toggleActions: PropTypes.func,
}

export default ExhibitionItem;