import React, { useEffect, useState, useCallback } from 'react';
import { map, isEmpty } from 'lodash';
import { Empty, Modal, Button } from 'antd';
import Slider from 'react-slick';
import { useLocalize } from 'localize-react';
import Layout from '../../components/Layout/Layout';
import { Icon } from '../../components/Icon/Icon';
import Loading from '../../components/Loading/Loading';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import GalleryModal from './GalleryModal';
import { BREAKPOINT_XS, DESKTOP_SIZE } from '../../enums';
import { STATUS } from '../../reducer/data-loading-reducer';

import { BoothProvider, useBoothContext } from '../../contexts/BoothContext';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';
import './Galleries.scss';

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={style} onClick={onClick}>
      <i className="fas fa-arrow-right"></i>
    </button>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={style} onClick={onClick}>
      <i className="fas fa-arrow-left"></i>
    </button>
  );
}

const settings = {
  centerMode: true,
  centerPadding: '60px',
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  // variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: DESKTOP_SIZE,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: BREAKPOINT_XS,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const GalleriesDesktop = ({ search, onClickGallery }: { search: string; onClickGallery: any }) => {
  const { galleries, onSearch, isViewDetail, status } = useBoothContext();
  const { translate } = useLocalize();

  useEffect(() => {
    onSearch(search);
  }, [onSearch, search]);

  if (status === STATUS.LOADING) {
    return (<Loading />)
  };

  return (
    <div className="GalleriesDesktop">
      {status === STATUS.LOADED && isEmpty(galleries) ? (
        <div className="empty-box">
          <Empty
            description={<span className="message-box">{translate('no_result_found')}</span>}
          />
        </div>
      ) : (
          <div className="gallery-container">
            <Slider {...settings} draggable={!isViewDetail} swipe={!isViewDetail}>
              {React.Children.toArray(
                map(galleries, (gallery, index) => (
                  <div className="gallery-item-holder">
                    <GalleryItem className="GalleryItem" onClick={() => onClickGallery(gallery, index)}>
                      <span className="d-inline-block position-relative">
                        <img src={gallery?.url} alt="" />
                        <Button type="link" onClick={() => onClickGallery(gallery, index)}>
                          <Icon className="fas fa-search-plus" />
                        </Button>
                      </span>

                    </GalleryItem>
                  </div>
                ))
              )}
            </Slider>
          </div>
        )}
    </div>
  );
};
const Gallery = () => {
  const { changeViewDetail } = useBoothContext();
  const { config } = useConfigurationContext();
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [target, setTarget] = useState(null);
  const [startIndex, setStartIndex] = useState(0);

  const handleShowGallery = useCallback(
    (gallery, index) => {
      setVisible(true);
      setTarget(gallery);
      setStartIndex(index);
      changeViewDetail(true);
    },
    [setVisible, changeViewDetail, setTarget]
  );

  const closeModal = useCallback(() => {
    setVisible(false);
    setTarget(null);
    setStartIndex(0);
    changeViewDetail(false);
  }, [setVisible, changeViewDetail, setTarget]);

  return (
    <BoothProvider>
      <Layout
        searchable
        onSearch={(value: string) => setSearch(value)}
      >
        <style dangerouslySetInnerHTML={{
          __html: `
        .gallery-container .slick-track { background-image: url(${config?.gallery}) }
      `}} />
        <GalleriesDesktop search={search} onClickGallery={handleShowGallery} />
        <Modal
          zIndex={9999}
          wrapClassName="modal-gallery"
          width="80vw"
          centered
          visible={visible}
          onCancel={closeModal}
          footer={null}
        >
          <GalleryModal target={target} startIndex={startIndex} />
        </Modal>
      </Layout>
    </BoothProvider>
  );
};

export default Gallery;
