import axios, { Method } from 'axios';
import { message } from 'antd';

axios.defaults.timeout = 20000;

export interface RequestOptions {
  data?: Record<string, unknown> | unknown;
  params?: Record<string, unknown>;
  headers?: Record<string, string>;
}

export const request = async <E>(
  method: Method,
  url: string,
  options: RequestOptions
): Promise<E> => {
  try {
    const { data } = await axios.request<E>({ method, url, ...options });

    return data;
  } catch (error) {
    message.error(error?.response?.data?.message || error.message);
    console.error(`Unexpected error when requesting ${method} ${url}`, error);
    throw error;
  }
};

export interface CreateRequestOptions {
  baseURL: string;
}

export const createRequest = <E>(baseOptions: CreateRequestOptions) => {
  return {
    get: async <E>(url: string, options: RequestOptions = {}) => {
      return request<E>('GET', url, { ...options, ...baseOptions });
    },
    post: async <E>(url: string, options: RequestOptions = {}) => {
      return request<E>('POST', url, { ...options, ...baseOptions });
    },
    put: async <E>(url: string, options: RequestOptions = {}) => {
      return request<E>('PUT', url, { ...options, ...baseOptions });
    },
    patch: async <E>(url: string, options: RequestOptions = {}) => {
      return request<E>('PATCH', url, { ...options, ...baseOptions });
    },
    remove: async <E>(url: string, options: RequestOptions = {}) => {
      return request<E>('DELETE', url, { ...options, ...baseOptions });
    },
  };
};
