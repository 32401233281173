import React from 'react';
import PropTypes from 'prop-types'

const ExhibitionBackground = ({ backgroundImage, bgPosition }) => {
  return (
    <div
      style={{
        backgroundImage: `cache-url(${backgroundImage})`,
        backgroundPosition: `${bgPosition.x}px ${bgPosition.y}`
      }}
      className={'exhibition-hall-background'} />
  );
}

ExhibitionBackground.propTypes = {
  bgPosition: PropTypes.object,
  backgroundImage: PropTypes.string
}

export default ExhibitionBackground;