import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { useLocalize } from 'localize-react';

import { Flex, FlexProps } from '../../../components/Flex/Flex';
import Avatar from '../../../components/Avatar/Avatar';
import Text from '../../../components/Text/Text';
import { Booth } from '../../../utils/interfaces';
import { onOpenMessenger } from '../../../utils/displays';
import { Icon } from '../../../components/Icon/Icon';
import { media, hidden, flex } from '../../../utils/styles';

const Logo = ({ logo }: { logo?: string }) => {
  const { translate } = useLocalize();

  return (
    <>
      <Flex
        mobile={hidden}
        desktop={flex}
        flexDirection="column"
        alignItems="center"
        margin="16px 0 8px 0"
      >
        {logo && <Avatar size={104} src={logo} />}
        {!logo && <Avatar size={104}>{translate('common.logo')}</Avatar>}
      </Flex>

      <Flex
        mobile={flex}
        desktop={hidden}
        flexDirection="column"
        alignItems="center"
        margin="16px 0 8px 0"
      >
        {logo && <Avatar size={60} src={logo} />}
        {!logo && <Avatar size={60}>{translate('common.logo')}</Avatar>}
      </Flex>
    </>
  );
};

const Name = ({ name }: { name?: string }) => {
  return (
    <>
      <Flex mobile={hidden} desktop={flex} alignItems="center" flexDirection="column">
        <Text color="#324d75" fontSize="32px" fontWeight="500">
          {name}
        </Text>
        <Text color="#8d8d8d" fontSize="20px" fontWeight="300">
          {name}
        </Text>
      </Flex>
      <Flex mobile={flex} desktop={hidden} alignItems="center" flexDirection="column">
        <Text color="#324d75" fontSize="18px" fontWeight="500">
          {name}
        </Text>
        <Text color="#8d8d8d" fontSize="14px" fontWeight="300">
          {name}
        </Text>
      </Flex>
    </>
  );
};

export interface TextDescriptionProps extends FlexProps {
  color?: string;
}

const TextDescription = ({
  bg = '#ffffff',
  borderRadius = '12px',
  children,
  ...props
}: PropsWithChildren<TextDescriptionProps>) => {
  return (
    <Flex bg={bg} borderRadius={borderRadius} overflow="auto" {...props}>
      {children}
    </Flex>
  );
};

const Description = ({ description }: { description?: string }) => {
  return (
    <>
      <Flex mobile={hidden} desktop={flex} flex="1 1 auto" minHeight="0" justifyContent="center">
        <TextDescription flex="1 1 auto" padding="20px 24px" margin="16px 24px">
          <Text fontSize="16px" color="#666666">
            {description}
          </Text>
        </TextDescription>
      </Flex>

      <Flex mobile={flex} desktop={hidden} flex="1 1 auto" minHeight="0" justifyContent="center">
        <TextDescription flex="1 1 auto" margin="12px 16px">
          <Text fontSize="14px" color="#666666">
            {description}
          </Text>
        </TextDescription>
      </Flex>
    </>
  );
};

const ActionItem = styled(Flex).attrs(() => ({
  flex: '0 0 50%',
  justifyContent: 'center',
  alignItems: 'center',
}))`
  &:not(:last-child) {
    border-right: 1px solid #4c6386;
  }
`;

const ActionsContainer = styled(Flex).attrs(() => ({
  bg: '#324d75',
  color: '#ffffff',
  justifyContent: 'center',
}))`
  ${media.mobile(css`
    border-radius: 10px;
    margin: 8px 16px 12px 16px;
    padding: 8px;
  `)};

  ${media.desktop(css`
    border-radius: 20px;
    margin: 16px 32px 24px 32px;
    padding: 16px;
  `)};
`;

const Actions = ({ data }: { data?: Booth }) => {
  const { translate } = useLocalize();

  if (!data) {
    return null;
  }

  return (
    <ActionsContainer>
      <ActionItem onClick={onOpenMessenger(data.live_support)}>
        <Icon className="fas fa-comments fa-fw" margin="0 8px 0 0" fontSize="24px" />
        {translate('common.chat')}
      </ActionItem>
      <ActionItem>
        <a href={`mailto:${data.email}`}>
          <Text color="#ffffff">{translate('common.email')}</Text>
        </a>
      </ActionItem>
    </ActionsContainer>
  );
};

export interface ExhibitionInformationProps {
  data?: Booth;
}

const ExhibitionInformationContainer = styled(Flex).attrs(() => ({
  bg: '#ffffff',
  flex: '1 1 auto',
  flexDirection: 'column',
}))`
  ${media.mobile(css`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  `)};
  ${media.desktop(css`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  `)};
`;

const ExhibitionInformation = ({ data }: ExhibitionInformationProps) => {
  return (
    <ExhibitionInformationContainer>
      <Flex mobile={hidden} desktop={flex} flexDirection="column" alignItems="center">
        <Logo logo={data?.logo} />
        <Name name={data?.name} />
      </Flex>
      <Flex mobile={flex} desktop={hidden} alignItems="center" margin="0 0 0 16px">
        <Flex marginRight="16px">
          <Logo logo={data?.logo} />
        </Flex>
        <Name name={data?.name} />
      </Flex>
      <Description description={data?.description} />
      <Actions data={data} />
    </ExhibitionInformationContainer>
  );
};

export default ExhibitionInformation;
