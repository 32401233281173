import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useMediaQuery } from 'react-responsive';
import { useBoothContext } from '../../contexts/BoothContext';
import { BoothGallery } from '../../utils/interfaces';
import { DESKTOP_SIZE } from '../../enums';
import './GalleryModal.scss';

const GalleryModal = ({
  target,
  startIndex,
}: {
  target: BoothGallery | null;
  startIndex: number;
}) => {
  const { galleries } = useBoothContext();
  const isDesktop = useMediaQuery({ minWidth: DESKTOP_SIZE });
  const [activeGallery, setActiveGallery] = useState(galleries[startIndex]);

  const onSlide = React.useCallback(
    (currentIndex) => {
      setActiveGallery(galleries[currentIndex]);
    },
    [galleries, setActiveGallery]
  );

  if (!target) {
    return null;
  }

  const galleriesTransformed = galleries.map((item) => ({
    ...item,
    original: item.url,
    thumbnail: item.url,
  }));

  return (
    <div className="GalleryModal">
      <ImageGallery
        startIndex={startIndex}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={false}
        thumbnailPosition={isDesktop ? 'right' : 'bottom'}
        items={galleriesTransformed}
        onSlide={onSlide}
      />
      <div className="GalleryModal__info">
        <div className="GalleryModal__info-title">{activeGallery?.name}</div>
        <div className="GalleryModal__info-desc">{activeGallery?.description}</div>
      </div>
    </div>
  );
};

export default GalleryModal;
