import React from 'react';
import { Image } from 'antd';
import CONFIG from '../../config.json';

export interface PreviewImageProps {
    src?: string;
}

const PreviewImage = ({ src }: PreviewImageProps) => {
    if (src) {
        return (
            <Image src={src} />
        );
    }

    return (
        <Image src='error' fallback={CONFIG.default_image} />
    );
};

export default PreviewImage;