import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, Menu as MenuComponent, Button } from 'antd';
import { useLocalize } from 'localize-react';
import './Menu.scss';

import { EXHIBITION_PATH, GALLERY_PATH, WORKSHOP_PATH, HOME_PATH } from '../../Routes';

const Menu = () => {
  const history = useHistory();
  const { translate } = useLocalize();
  const { logout } = useAuth0();

  const onLogOut = () => {
    if (process.env.NODE_ENV === 'development') {
      logout({
        returnTo: 'http://localhost:3000'
      })
    } else {
      logout({
        returnTo: window.location.origin
      })
    }
  }

  const menu = (
    <MenuComponent className="mst-menu">
      <MenuComponent.Item onClick={() => history.push('/')}>
        {translate('common.home')}
      </MenuComponent.Item>
      <MenuComponent.Item onClick={() => history.push(HOME_PATH)}>
        {translate('common.hall')}
      </MenuComponent.Item>
      <MenuComponent.Item onClick={() => history.push(EXHIBITION_PATH)}>
        {translate('common.exhibition')}
      </MenuComponent.Item>
      <MenuComponent.Item onClick={() => history.push(GALLERY_PATH)}>
        {translate('common.gallery')}
      </MenuComponent.Item>
      <MenuComponent.Item onClick={() => history.push(WORKSHOP_PATH)}>
        {translate('common.workshop')}
      </MenuComponent.Item>
      <MenuComponent.Item onClick={onLogOut}>
        {translate('logout')}
      </MenuComponent.Item>
    </MenuComponent>
  );

  return (
    <Dropdown className="mst-menu" overlay={menu} trigger={['click']}>
      <Button shape="circle" icon={<MenuOutlined />} size="large" />
    </Dropdown>
  );
};

export default Menu;
