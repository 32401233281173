import styled from 'styled-components';
import { DESKTOP_SIZE } from '../../enums';

const GalleryItem = styled.div`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  height: 400px;
  position: relative;
  cursor: pointer;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover,
  &:focus {
    filter: brightness(1.2);
    outline: none;
  }
  @media (max-width: ${DESKTOP_SIZE}px) {
    height: 25vh;
  }
  @media (orientation: landscape) {
    height: 50vh;
  }
  img {
    height: auto;
    width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  }

  &:before {
    content: "";
    display: none;
    
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    position: absolute;  
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }  
  .ant-btn {
    color: #fff;
  }
`;

export default GalleryItem;
