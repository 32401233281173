import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Home from './views/Home/Home';
import Exhibition from './views/Exhibition/Exhibition';
import Gallery from './views/Gallery/Gallery';
import ExhibitionDetail from './views/ExhibitionDetail/ExhibitionDetail';
import Workshop from './views/Workshop/Workshop';
import Admin from './views/Admin/Admin';
import Main from './views/Main/Main';

export const EXHIBITION_PATH = '/exhibition';

export const GALLERY_PATH = '/gallery';

export const WORKSHOP_PATH = '/workshop';

export const ADMIN_PATH = '/admin';

export const HOME_PATH = '/home';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        <AuthProvider>
          <Route exact path={HOME_PATH} component={Home} />
          <Route exact path={EXHIBITION_PATH} component={Exhibition} />
          <Route exact path={`${EXHIBITION_PATH}/:id`} component={ExhibitionDetail} />
          <Route exact path={WORKSHOP_PATH} component={Workshop} />
          <Route exact path={GALLERY_PATH} component={Gallery} />
          <Route path={ADMIN_PATH} component={Admin} />
        </AuthProvider>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
