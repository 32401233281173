import { Avatar } from 'antd';
import styled from 'styled-components';

const AvatarComponent = styled(Avatar)`
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #797979;
  font-size: 16px;
  font-weight: 600;
`;

export default AvatarComponent;
