import React, { useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';
import { map, isEmpty } from 'lodash';
import { Modal } from 'antd';
import ExhibitionGallery from './ExhibitionGallery';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import { Booth } from '../../utils/interfaces';
import { Desktop, Mobile } from '../../components/Responsive/Responsive';
import { BoothProvider, useBoothContext } from '../../contexts/BoothContext';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';
import { STATUS } from '../../reducer/data-loading-reducer';

import ExhibitionBackground from './ExhibitionBackground'
import Background from './Background'

import './Exhibition.scss';
import ExhibitionItem from './ExhibitionItem';
import { filterHiddenBooth } from '../../api/booths'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const settingsMobile = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ExhibitionDesktop = ({
  search,
  handleShowGallery,
  moveBackground,
  toggleActions,
  showActionsIndex,
}: {
  search: string;
  handleShowGallery: (value: Booth) => void;
  moveBackground: (value: number) => void;
  toggleActions: (index: number) => void;
  showActionsIndex: number;
}) => {
  const { data, onSearch, isViewDetail, status } = useBoothContext();

  useEffect(() => {
    onSearch(search);
  }, [onSearch, search]);

  if (status === STATUS.LOADING) {
    return <Loading />;
  }

  const onBeforeChange = (current: number, next: number) => {
    moveBackground(next)
  }

  return (
    <div className="ExhibitionContainer">
      {status === STATUS.LOADED && isEmpty(data) ? (
        <div className="empty-box"></div>
      ) : (
          <Slider
            {...settings}
            draggable={!isViewDetail}
            swipe={!isViewDetail}
            className={isViewDetail ? 'un-draggable' : 'draggable'}
            beforeChange={onBeforeChange}
          >
            {React.Children.toArray(
              map(data?.filter(filterHiddenBooth), (item, index) => (
                <ExhibitionItem
                  isShowActions={index === showActionsIndex}
                  handleShowGallery={handleShowGallery}
                  item={item}
                  toggleActions={() => toggleActions(index)}
                />
              ))
            )}
          </Slider>
        )}
    </div>
  );
};

const ExhibitionMobile = ({
  search,
  handleShowGallery,
  moveBackground,
  toggleActions,
  showActionsIndex,
}: {
  search: string;
  handleShowGallery: (value: Booth) => void;
  moveBackground: (value: number) => void;
  toggleActions: (index: number) => void;
  showActionsIndex: number;
}) => {
  const { data, onSearch, isViewDetail } = useBoothContext();

  const onBeforeChange = (current: number, next: number) => {
    moveBackground(next);
  }

  useEffect(() => {
    onSearch(search);
  }, [onSearch, search]);

  return (
    <div className="ExhibitionContainer ExhibitionContainer-mobile">
      {isEmpty(data) ? (
        <div className="empty-box"></div>
      ) : (
          <Slider
            {...settingsMobile}
            draggable={!isViewDetail}
            swipe={!isViewDetail}
            className={isViewDetail ? 'un-draggable' : 'draggable'}
            beforeChange={onBeforeChange}
          >
            {React.Children.toArray(
              map(data, (item, index) => (
                <ExhibitionItem
                  isShowActions={index === showActionsIndex}
                  handleShowGallery={handleShowGallery}
                  item={item}
                  toggleActions={() => toggleActions(index)}
                />
              ))
            )}
          </Slider>
        )}
    </div>
  );
};

const Exhibition = () => {
  const { config } = useConfigurationContext();
  const { changeViewDetail } = useBoothContext();
  const [search, setSearch] = useState('');
  const [target, setTarget] = useState();
  const [visible, setVisible] = useState(false);
  const [bgPosition, setBgPosition] = useState({ x: 0, y: 'bottom' })
  const [showActionsIndex, setShowActionsIndex] = useState(-1);

  const toggleActions = (index: number) => {
    setShowActionsIndex(index);
  }

  const moveBackground = (value: number) => {
    setShowActionsIndex(-1);
    const offset = 100;
    setBgPosition({
      x: - offset * value,
      y: 'bottom'
    })
  }

  const handleShowGallery = useCallback(
    (data) => {
      setVisible(true);
      changeViewDetail(true);
      setTarget(data);
    },
    [setVisible, changeViewDetail]
  );

  const closeModal = useCallback(() => {
    setVisible(false);
    changeViewDetail(false);
    setTarget(undefined);
  }, [setVisible, changeViewDetail]);

  return (
    <BoothProvider>
      <ExhibitionBackground backgroundImage={config?.booths} bgPosition={bgPosition} />
      <Background config={config} />
      <Layout
        searchable
        onSearch={(value: string) => setSearch(value)}
      >
        <Desktop>
          <ExhibitionDesktop toggleActions={toggleActions} search={search} handleShowGallery={handleShowGallery} moveBackground={moveBackground} showActionsIndex={showActionsIndex} />
        </Desktop>
        <Mobile>
          <ExhibitionMobile toggleActions={toggleActions} search={search} handleShowGallery={handleShowGallery} moveBackground={moveBackground} showActionsIndex={showActionsIndex} />
        </Mobile>
        <Modal
          zIndex={9999}
          className="modal-exhibition"
          width="80vw"
          centered
          visible={visible}
          onCancel={closeModal}
          footer={null}
        >
          <ExhibitionGallery target={target} />
        </Modal>
      </Layout>
    </BoothProvider>
  );
};

export default Exhibition;
