import React, { useState } from 'react';
import { Button, Form, Input, Upload, Row, Col, Skeleton, message, Switch } from 'antd';
import { useLocalize } from 'localize-react';
import styled from 'styled-components';
import api from '../../../api';
import { Config } from '../../../utils/interfaces';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';
import { STATUS } from '../../../reducer/data-loading-reducer';
import PreviewImage from '../../../components/PreviewImage/PreviewImage';

const UploadContainer = styled.div`
  padding-top: 29px;
`;

const Configuration = () => {
  const { translate } = useLocalize();
  const [form] = Form.useForm();
  const { accessToken: token } = useAuthContext();
  const { config, status, dispatch } = useConfigurationContext();
  const [loading, setLoading] = useState({
    booths: false,
    booth: false,
    home: false,
    home_landscape: false,
    document: false,
    gallery: false,
    no_exhibition: false,
    booths_video: false,
  });
  const [exhibitionDisabled, setExhibitionDisabled] = useState(false);

  const onSubmit = async (form: object) => {
    try {
      dispatch({ type: 'DATA_UPDATING' });
      const newData = await api.workshop.createOrUpdate(
        { ...config, ...form, no_exhibition: exhibitionDisabled.toString() } as Config,
        token || ''
      );
      dispatch({ type: 'DATA_LOADED', payload: newData })
      message.success(translate('added_success'));
    } catch (error) {
      throw error;
    }
  };

  const onUpload = (field: string) => (file: File) => {
    const upload = async () => {
      const url = await api.files.upload(file, token || '');

      form.setFieldsValue({ [field]: url });
      setLoading((prev) => ({ ...prev, [field]: false }));
    };
    setLoading((prev) => ({ ...prev, [field]: true }));
    upload();

    return false;
  };


  return (
    <>
      {status === STATUS.LOADING && <Skeleton active />}
      {![STATUS.LOADING, STATUS.ERROR].includes(status || '') && (
        <Form
          layout="vertical"
          form={form}
          initialValues={config}
          style={{ width: '100%' }}
          onFinish={onSubmit}
        >
          <Form.Item label="Exhibition Disabled" name="no_exhibition" rules={[]}>
            <Switch defaultChecked={config?.no_exhibition === 'true'} onChange={setExhibitionDisabled} />
          </Form.Item>
          <hr />
          <Form.Item label="3D URL" name="url3d" rules={[]}>
            <Input />
          </Form.Item>
          <hr />
          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span="2">
              <PreviewImage src={config?.home} />
            </Col>
            <Col span={18}>
              <Form.Item label="Home Background (Portrait)" name="home" rules={[]}>
                <Input disabled={loading.home} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('home')}
                  showUploadList={false}
                  disabled={loading.home}
                >
                  <Button disabled={loading.home} block loading={loading.home}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span="2">
              <PreviewImage src={config?.home_landscape} />
            </Col>
            <Col span={18}>
              <Form.Item label="Home Background (Landscape)" name="home_landscape" rules={[]}>
                <Input disabled={loading.home_landscape} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('home_landscape')}
                  showUploadList={false}
                  disabled={loading.home_landscape}
                >
                  <Button disabled={loading.home_landscape} block loading={loading.home_landscape}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <hr />
          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span="2">
              <PreviewImage src={config?.booths} />
            </Col>
            <Col span={18}>
              <Form.Item label="Exhibition Background" name="booths" rules={[]}>
                <Input disabled={loading.booths} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('booths')}
                  showUploadList={false}
                  disabled={loading.booths}
                >
                  <Button block disabled={loading.booths} loading={loading.booths}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <hr />
          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span="2">
              <PreviewImage src={config?.booths_video} />
            </Col>
            <Col span={18}>
              <Form.Item label="Exhibition Background Video (Safari Only)" name="booths_video" rules={[]}>
                <Input disabled={loading.booths_video} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('booths_video')}
                  showUploadList={false}
                  disabled={loading.booths_video}
                >
                  <Button block disabled={loading.booths_video} loading={loading.booths_video}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <hr />
          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span="2">
              <PreviewImage src={config?.gallery} />
            </Col>
            <Col span={18}>
              <Form.Item label="Gallery Background" name="gallery" rules={[]}>
                <Input disabled={loading.gallery} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('gallery')}
                  showUploadList={false}
                  disabled={loading.gallery}
                >
                  <Button block disabled={loading.gallery} loading={loading.gallery}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <hr />

          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span={20}>
              <Form.Item label="Document of Workshop" name="document" rules={[]}>
                <Input disabled={loading.document} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <UploadContainer>
                <Upload
                  beforeUpload={onUpload('document')}
                  showUploadList={false}
                  disabled={loading.document}
                >
                  <Button disabled={loading.document} loading={loading.document}>
                    Upload
                  </Button>
                </Upload>
              </UploadContainer>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={status === STATUS.UPDATING}
              size="large"
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Configuration;
