import React, { useReducer, useEffect } from 'react';
import { Form, Input, Button, Space, Skeleton, Badge } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { IAgenda } from '../../../utils/interfaces';
import api from '../../../api';
import { Flex } from '../../../components/Flex/Flex';
import { useAuthContext } from '../../../contexts/AuthContext';
import { dataLoadingReducer, initData, IDataState, Action, STATUS } from '../../../reducer/data-loading-reducer';

const Agenda = () => {
  const { accessToken: token } = useAuthContext();
  const [{ status, data }, dispatch] = useReducer<React.Reducer<IDataState<IAgenda>, Action>>(
    dataLoadingReducer,
    initData
  );

  useEffect(() => {
    const init = async () => {
      try {
        if (token) {
          dispatch({ type: 'DATA_LOADING' });
          const { data } = await api.agenda.findAll(token);
          dispatch({ type: 'DATA_LOADED', payload: data });
        }
      } catch (error) {
        dispatch({ type: 'DATA_ERROR' });
        throw error;
      }
    };

    init();
  }, [token]);

  const onFinish = async (values: any) => {
    try {
      if (token) {
        dispatch({ type: 'DATA_LOADING' });
        await api.agenda.createOrUpdate({
          id: data?.id,
          token,
          data: values?.agenda
        });
        dispatch({ type: 'DATA_LOADED', payload: values?.agenda });
      }
    } catch (error) {
      dispatch({ type: 'DATA_ERROR' });
    }
  };

  return (
    <Flex margin="24px" flex="0 0 50%" flexDirection="column">
      {status === STATUS.LOADING && <Skeleton active />}
      {![STATUS.LOADING, STATUS.ERROR].includes(status || '') && (
        <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" initialValues={data} className="common-form">
          <Form.List name="agenda">
            {(fields, { add, remove }) => {
              return (
                <div>
                  <Form.Item>
                    <div className="text-right">
                      <Button
                        onClick={() => {
                          add();
                        }}
                      >
                        <PlusOutlined /> Add
                      </Button>
                    </div>
                  </Form.Item>

                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Space
                        
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="start"
                      >
                        <Badge count={index + 1} style={{ backgroundColor: '#cda05c' }} />
                        <Form.Item
                          {...field}
                          name={[field.name, 'time']}
                          fieldKey={[field.fieldKey, 'time']}
                          rules={[{ required: true, message: 'Missing time' }]}
                        >
                          <Input placeholder="Time" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'title']}
                          fieldKey={[field.fieldKey, 'title']}
                          rules={[{ required: true, message: 'Missing title' }]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          fieldKey={[field.fieldKey, 'title']}
                          rules={[{ required: true, message: 'Missing description' }]}
                        >
                          <Input.TextArea placeholder="Description" />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Space>
                      <hr />
                    </div>
                  ))}
                </div>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Flex>
  );
};

export default Agenda;
