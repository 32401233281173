import React from 'react'
const userAgent = window.navigator.userAgent;
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)) || userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);


const Background = ({ config }) => {
  if (isSafari) {
    return (
      <style dangerouslySetInnerHTML={{
        __html: `
          .ExhibitionContainer .slick-track { background-image: url(${config?.booths_video}) }
        `}} />
    )
  }


  return (
    <style dangerouslySetInnerHTML={{
      __html: `
        .ExhibitionContainer .slick-track { background-image: url(${config?.booths}) }
      `}} />
  )
}

export default Background