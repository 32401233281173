import '@fortawesome/fontawesome-free/css/all.min.css';
import { css, createGlobalStyle } from 'styled-components';

const GlobalStyle = css`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, Arial, Helvetica Neue, sans-serif;
    font-size: 16px;
  }

  img {
    max-width: 100%;
  }

  hr {
    margin-bottom: 25px;
    border-color: rgba(0, 0, 0, .03);
  }
  
  .ant-btn {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }
  .ant-btn.ant-btn-link {
    box-shadow: none;
  }
  .ant-btn.ant-btn-link:hover,
  .ant-btn.ant-btn-link:focus {
    opacity: .6;
  }
  .ant-dropdown-menu {
    border-radius: 10px;
    overflow: hidden;
  }
  .ant-modal-mask {
    backdrop-filter: blur(0.5rem);
    background-color: transparent;
  }
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-body {
    padding: 0;
  }
  .site-layout .ant-page-header {
    padding-left: 0;
    padding-right: 0;
  }
  .common-form {
    max-width: 600px;
  }
  .common-link {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  .common-link:hover,
  .common-link:focus {
    outline: none;
    filter: brightness(1.2);
  }
`;

export default createGlobalStyle`
  ${GlobalStyle};
`;
