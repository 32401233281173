import React, { ReactNode, useState } from 'react';
import { Swipeable } from 'react-swipeable';
import styled, { css } from 'styled-components';

import { Flex } from '../../components/Flex/Flex';

export interface CarouselProps {
  items: Array<ReactNode>;
}

interface CaretPositionProps {
  active?: boolean;
}

const activeCarouselPosition = css`
  &::after {
    display: block;
    background-color: #324d75;
    border-radius: 50%;
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CarouselPosition = styled.div<CaretPositionProps>`
  position: relative;
  background-color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${({ active }) => active && activeCarouselPosition};
`;

const Carousel = ({ items }: CarouselProps) => {
  const [index, setIndex] = useState(0);

  const current = items[index];

  const onSwipe = ({ forward }: { forward: boolean }) => () => {
    if (forward && index < items.length - 1) {
      setIndex((prev: number) => prev + 1);
      return;
    }

    if (!forward && index > 0) {
      setIndex((prev: number) => prev - 1);
      return;
    }
  };

  return (
    <Flex flex="1 1 auto" position="relative">
      <Swipeable
        style={{ display: 'flex', flex: '1 1 auto' }}
        onSwipedLeft={onSwipe({ forward: false })}
        onSwipedRight={onSwipe({ forward: true })}
        trackTouch
        trackMouse
      >
        {current}
      </Swipeable>
      <Flex position="absolute" bottom="16px" width="100%" flex="1 1 auto" justifyContent="center">
        {items.map((_item, itemIndex) => (
          <CarouselPosition
            key={itemIndex}
            onClick={() => setIndex(itemIndex)}
            active={itemIndex === index}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Carousel;
