import React from 'react';
import { LoginOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { HOME_PATH } from '../../Routes';
import { useHistory } from 'react-router-dom';
import { useLocalize } from 'localize-react';
import { useConfigurationContext } from '../../contexts/ConfigurationContext';
import './Main.scss';

const Main = () => {
  const { config } = useConfigurationContext();
  const history = useHistory();

  const { translate } = useLocalize();

  return (
    <div className="Main">
      <div className="homepage-bg bg-portrait" style={{ backgroundImage: `url(${config?.home})` }} />
      <div className="homepage-bg bg-landscape" style={{ backgroundImage: `url(${config?.home_landscape})` }} />
      <div className="main-container">
        <div className="text-center">
          {config?.no_exhibition === 'false' && (
            <Button
              size="large"
              type="primary"
              shape="round"
              className="enter-button"
              onClick={() => history.push(HOME_PATH)}
              icon={<LoginOutlined />}
            >
              {translate('common.login')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;
