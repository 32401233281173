import { createRequest } from '../utils/request';

const { REACT_APP_API_ENDPOINT } = process.env;

const { get } = createRequest({ baseURL: `${REACT_APP_API_ENDPOINT}` });

const author = async (token: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const { data } = await get('/verify', { headers });
  return data;
};



export default { author };
