import styled from 'styled-components';
import {
  color,
  ColorProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
} from 'styled-system';

export type IconProps = ColorProps & PositionProps & TypographyProps & SpaceProps;

export const Icon = styled.span<IconProps>`
  ${color};
  ${position};
  ${typography};
  ${space};
  cursor: pointer;
`;
