import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Booth from './Booth/Booth';
import Booths from './Booths/Booths';
import { ADMIN_PATH } from '../../Routes';
import { AdminProvider } from '../../contexts/AdminContext';
import LayoutAdmin from './LayoutAdmin/LayoutAdmin';
import Configuration from './Configuration/Configuration';
import Agenda from './Agenda/Agenda';

const Admin = () => {
  return (
    <AdminProvider>
      <LayoutAdmin>
        <Switch>
          <Route exact path={`${ADMIN_PATH}/booths`} component={Booths} />
          <Route exact path={`${ADMIN_PATH}/booths/:id`} component={Booth} />
          <Route exact path={`${ADMIN_PATH}/configuration`} component={Configuration} />
          <Route exact path={`${ADMIN_PATH}/agenda`} component={Agenda} />
          <Redirect to={`${ADMIN_PATH}/booths`} />
        </Switch>
      </LayoutAdmin>
    </AdminProvider>
  );
};

export default Admin;
