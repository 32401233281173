import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/Loading/Loading';

export interface AuthContextValue {
  accessToken?: string;
}

const AuthContext = createContext<AuthContextValue>({});

export const useAuthContext = () => useContext(AuthContext);

export interface AuthProviderProps {}

export const AuthProvider = ({ children }: PropsWithChildren<AuthProviderProps>) => {
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    if (isAuthenticated) {
      const getAccessToken = async () => {
        setAccessToken(await getAccessTokenSilently());
      };

      getAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, accessToken]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  
    return null;
  }

  return <AuthContext.Provider value={{ accessToken }}>{children}</AuthContext.Provider>;
};
