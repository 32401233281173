const theme = {
  breakpoints: ['992px'],
  button: {
    circle: {
      primary: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        color: '#324d75',
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
      },
      secondary: {
        backgroundColor: '#324d75',
        color: '#ffffff',
      },
    },
  },
};

export default theme;
