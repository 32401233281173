import React, { PropsWithChildren } from 'react';
import Styled from 'styled-components';

import { Flex } from '../Flex/Flex';
import Menu from '../Menu/Menu';
import SearchBox from '../SearchBox/SearchBox';

const BoxMenu = Styled.div`
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 999;
`;

export interface LayoutProps {
  backgroundImage?: string;
  searchable?: boolean;
  onSearch?: (value: string) => void;
}

const Layout = ({
  children,
  backgroundImage,
  searchable = false,
  onSearch,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <Flex
      flex="1 1 auto"
      flexDirection="column"
      position="relative"
      backgroundImage={`url(${backgroundImage})`}
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      style={{ backgroundAttachment: 'fixed' }}
    >
      {children}
      <BoxMenu>
        <Menu />
      </BoxMenu>
      {searchable && (
        <SearchBox onSearch={onSearch} />
      )}
    </Flex>
  );
};

export default Layout;
